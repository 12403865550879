import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Course from "@/pages/Course/Course";
import PlayGround from "@/pages/PlayGround/PlayGround";
import Login from "@/pages/Login/Login";
import Layouts from "@/pages/Layouts/Layouts";
import HeaderLayout from "@/pages/Layouts/HeaderLayout";
import CheckUserName from "@/pages/CheckUserName/CheckUserName";
import AIChat from "@/pages/PlayGround/AIChat/AIChat";
import AIChatWS from "@/pages/PlayGround/AIChatWS/AIChatWS";
import { Navigate } from "react-router-dom";
import Lecture from "@/pages/LessonStudy/Lecture/Lecture";
import BoboMusic from "@/pages/PlayGround/BoboMusic/BoboMusic";
import Share from "@/pages/Share/Share";
import UserCenter from "@/pages/UserCenter/UserCenter";
import WeChatAuth from "@/pages/Login/WeChatAuth/WeChatAuth";

import { ReactNode } from "react";

function AuthRoute({ children, ...rest }: { children: ReactNode }) {
    const isLoggedIn = Boolean(localStorage.getItem('token'));
    return isLoggedIn ? children : <Navigate to="/login" />;
}

function RootRoute() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<CheckUserName />}>
                    <Route path="/" element={<AuthRoute><Layouts /></AuthRoute>}>
                        <Route index element={<Navigate to="/classroom" />} />
                        <Route path="/classroom" element={<AuthRoute><Course /></AuthRoute>} />
                        <Route path="/playground" element={<AuthRoute><PlayGround /></AuthRoute>} />
                        {/* <Route path="/coursechapter/:courseId" element={<AuthRoute><CourseChapter /></AuthRoute>} /> */}
                        <Route path="/user" element={<AuthRoute><UserCenter /></AuthRoute>} />
                    </Route>
                    {/* <Route path="/playground/ai-chat" element={<AuthRoute><AIChat /></AuthRoute>} /> */}
                    {/* <Route path="/chat/:courseId" element={<AuthRoute><ChatForInfo /></AuthRoute>} /> */}
                    {/* <Route path="/coursestudy/:courseId/:chapterId" element={<AuthRoute><LessonStudy /></AuthRoute>} /> */}
                    <Route path="/coursestudy/:courseId/:chapterId/:sectionId" element={<AuthRoute><Lecture /></AuthRoute>} />
                    <Route path="/playground" element={<HeaderLayout />} >
                        <Route path="/playground/bobo-music" element={<AuthRoute><BoboMusic /></AuthRoute>} />
                        <Route path="/playground/ai-chat" element={<AuthRoute><AIChat /></AuthRoute>} />
                        <Route path="/playground/ai-chat-ws" element={<AuthRoute><AIChatWS /></AuthRoute>} />
                    </Route>
                </Route>
                <Route path="/login" element={<Login />} />
                <Route path="/share/:md5/" element={<Share />} />
                <Route path="/wx_auth" element={<WeChatAuth />} />
            </Routes>
        </Router>
    );
}

export default RootRoute;
