import 'normalize.css';
import './App.css'
import "./basic.less";
import RootRoute from './router';
import { rootStore } from './store/rootStore';
import { storeContext } from './store/StoreContext';


function App() {
  return (
    <>
      <storeContext.Provider value={rootStore}>
        <RootRoute />
      </storeContext.Provider>
    </>
  )
}

export default App;
